export default class Servidor {
  constructor(
    id = null,
    nome = '',
    cpf = '',
    matricula = '',
    email = '',
    celular = '',
    data_nascimento,
    date,
    rg_numero,
    rg_uf,
    sexo,
    estado_civil_id,
    falecido,
    ativo,
    orgao,
    vinculo,
  ) {
    this.id = id
    this.nome = nome
    this.cpf = cpf
    this.email = email
    this.celular = celular
    this.data_nascimento = data_nascimento
    this.date = date
    this.rg_numero = rg_numero
    this.rg_uf = rg_uf
    this.sexo = sexo
    this.estado_civil_id = estado_civil_id
    this.falecido = falecido
    this.ativo = ativo
    this.matricula = matricula
    this.orgao = orgao
    this.vinculo = vinculo
  }
}
