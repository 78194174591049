<template>
  <div>
    <TabMenu :model="items" />
    <router-view />
    <Panel class="mt-3" header="Servidor">
      <div class="p-fluid formgrid grid d-flex">
        <div class="field col-12 md:col-3">
          <label for="nome">Nome</label>
          <InputText id="nome" v-model="servidor.nome" type="text" readonly />
        </div>
        <div class="field col-12 md:col-3">
          <label for="matricula">Matrícula</label>
          <InputText
            id="matricula"
            v-model="servidor.matricula"
            type="text"
            readonly />
        </div>
        <div class="field col-12 md:col-3">
          <label for="status">Status</label>
          <InputText
            id="status"
            v-model="servidor.status"
            type="text"
            readonly />
        </div>
        <div class="field col-12 md:col-3">
          <label for="referencia">Referência</label>
          <InputText
            id="referencia"
            v-model="servidor.referencia"
            type="text"
            readonly />
        </div>
      </div>
    </Panel>
    <div class="p-fluid d-flex col-12 mt-3">
      <Panel header="Descontos" class="col-6">
        <DataTable
          :value="descontos"
          striped-rows
          :paginator="true"
          :rows="10"
          :rows-per-page-options="[5, 10, 25]"
          responsive-layout="scroll">
          <Column field="rubrica" header="Rubrica"></Column>
          <Column field="descricao" header="Descrição"></Column>
          <Column field="certidao" header="Certidão"></Column>
          <Column field="padrao" header="Padrão"></Column>
          <Column field="valor" header="Valor"></Column>
        </DataTable>
      </Panel>

      <Panel class="ml-2 col-6" header="Cartão">
        <DataTable
          :value="cartoes"
          striped-rows
          :paginator="true"
          :rows="10"
          :rows-per-page-options="[5, 10, 25]"
          responsive-layout="scroll">
          <Column field="rubrica" header="Rubrica"></Column>
          <Column field="descricao" header="Descrição"></Column>
          <Column field="valor" header="Valor"></Column>
        </DataTable>
      </Panel>
    </div>
    <Panel
      header="Aguardando confirmação"
      class="mt-3"
      :style="'border-top: 4px solid #ffc107;'">
      <DataTable
        :value="aguardando_confirmacoes"
        striped-rows
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
        responsive-layout="scroll">
        <Column field="comprador" header="Comprador"></Column>
        <Column field="rubrica" header="Rubrica"></Column>
        <Column field="devedor" header="Devedor"></Column>
        <Column field="valor" header="Valor"></Column>
      </DataTable>
    </Panel>

    <Panel
      header="Compradas"
      class="mt-3"
      :style="'border-top: 4px solid #ffc107;'">
      <DataTable
        :value="compradas"
        striped-rows
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
        responsive-layout="scroll">
        <Column field="comprador" header="Comprador"></Column>
        <Column field="rubrica" header="Rubrica"></Column>
        <Column field="devedor" header="Devedor"></Column>
        <Column field="valor" header="Valor"></Column>
      </DataTable>
    </Panel>
    <Panel
      header="Margem consignável"
      class="mt-3"
      :style="'border-top: 4px solid #ffc107;'">
      <div class="p-fluid formgrid grid d-flex">
        <div class="field col-12 md:col-3">
          <label for="margem_consignavel">{{ labelMargem('margem1') }}</label>
          <InputText
            id="margem_consignavel"
            v-model="margem_consignavel"
            type="text"
            readonly />
        </div>
        <div class="field col-12 md:col-3">
          <label for="bens_duraveis">{{ labelMargem('margem2') }}</label>
          <InputText
            id="bens_duraveis"
            v-model="bens_duraveis"
            type="text"
            readonly />
        </div>
        <div class="field col-12 md:col-3">
          <label for="adiantamento">{{ labelMargem('margem3') }}</label>
          <InputText
            id="adiantamento"
            v-model="adiantamento"
            type="text"
            readonly />
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Servidor from '@/domain/Servidor.js'

export default {
  data() {
    return {
      servidor: new Servidor(),
      descontos: [],
      cartoes: [],
      compradas: [],
      aguardando_confirmacoes: [],
      margem_consignavel: [],
      items: [
        { label: 'Servidor', to: '/home/servidor' },
        { label: 'Margem Consignável', to: '/home/margem_consignavel' },
        { label: 'Consignações', to: '/tabmenu/' },
        { label: 'Propostas', to: '/tabmenu/' },
        { label: 'Histórico de Margem', to: '/tabmenu/' },
      ],
    }
  },

  methods: {
    labelMargem(chave) {
      let label = this.store.dados.configuracoes.find(
        (el) => el.chave === chave,
      )
      if (label) {
        return label?.valor
      }
      return chave
    },
  },
}
</script>

<style>
.panelStyle1 {
  border-top-color: #000;
}
</style>
